<template>
  <div class="job">
    <div class="jobTop">
      <div class="text">
        <p>互联网行业求职定制</p>
        <p>专属求职顾问一对一指导助您脱颖而出</p>
      </div>
      <img v-lazy="topBg" alt="" srcset="">
    </div>
    <!-- 求职者会遇到以下5大问题 -->
    <div class="jobProblem">
      <img v-lazy="problemTitle" alt="" srcset="">
      <img v-lazy="problemImg" alt="" srcset="">
    </div>
    <!-- 4大优势 -->
    <div class="jobAdvantage">
      <div class="jobAdvantage-top">
        <img v-lazy="advantageTitle" alt="" srcset="">
      </div>
      <div class="jobAdvantage-bottom">
        <div class="jobAdvantage-bottom-list">
          <div v-for="(item,index) in advantageList" :key="index" class="jobAdvantage-bottom-list-item">
            <img v-lazy="item.img" alt="">
            <p>{{ item.title }}</p>
            <p>{{ item.span1 }}</p>
            <p>{{ item.span2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务流程 -->
    <div class="jobProcess">
      <img v-lazy="processTitle" alt="" srcset="">
      <img v-lazy="processImg" alt="" srcset="">
    </div>
    <!-- 案例展示 -->
    <div class="jobExhibition">
      <div class="jobExhibition-top">
        <img v-lazy="exhibitionTitle" alt="" srcset="">
      </div>
      <div class="jobExhibition-center">
        <div>优化前</div>
        <img v-lazy="exhibitionVs" alt="" srcset="">
        <div>优化后</div>
      </div>
      <div class="jobExhibition-bottom">
        <img v-lazy="exhibitionImg1" alt="" srcset="">
        <img v-lazy="exhibitionImg2" alt="" srcset="">
      </div>
    </div>
    <div class="homeSolve">
      <div class="homeSolve-content">
        <div class="homeSolve-content-top">
          <p>提交需求获取解决方案</p>
          <p>欢迎咨询，提出您的需求，客服将尽心为您解答</p>

        </div>
        <div class="homeSolve-content-center">
          <div class="homeSolve-content-center-top">
            <input type="text" placeholder="请填写您的姓名（必填）" v-model="queryParams.name">
            <input type="text" placeholder="请填写您的联系方式（必填）" v-model="queryParams.phone">
          </div>
          <textarea name="" id="" placeholder="请描述一下您的需求" v-model="queryParams.demandDescribe"></textarea>
        </div>
        <div class="homeSolve-content-bottom">
          <button @click="messageLeave">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { messageLeave } from '@/api/index.js'

export default {
  data() {
    return {
        queryParams: {
        demandDescribe: '',
        name: '',
        phone: '',
      },
      topBg: require('../../assets/Web/job/topBg.png'),
      problemTitle: require('../../assets/Web/job/problem/title.png'),
      problemImg: require('../../assets/Web/job/problem/img.png'),
      processTitle: require('../../assets/Web/job/process/title.png'),
      processImg: require('../../assets/Web/job/process/img.png'),
      advantageTitle: require('../../assets/Web/job/advantage/title.png'),
      advantageList: [
        {
          img: require('../../assets/Web/job/advantage/1.png'),
          title: '互联网资深大咖',
          span1: '核心顾问均为10年',
          span2: '以上互联网行业资深大咖',
        },
        {
          img: require('../../assets/Web/job/advantage/2.png'),
          title: '一对一沟通',
          span1: '一对一精聊沟通，挖掘',
          span2: '自身优势，定制求职策略',
        },
        {
          img: require('../../assets/Web/job/advantage/3.png'),
          title: '梳理优化',
          span1: '简历结构化梳理，清晰',
          span2: '明了突出重点，排版简洁',
        },
        {
          img: require('../../assets/Web/job/advantage/4.png'),
          title: '售后服务',
          span1: '30天内免费修改调整，精准',
          span2: '命中HR，附带面试及薪资辅导',
        },
      ],
      exhibitionTitle: require('../../assets/Web/job/exhibition/title.png'),
      exhibitionVs: require('../../assets/Web/job/exhibition/vs.png'),
      exhibitionImg1: require('../../assets/Web/job/exhibition/1.png'),
      exhibitionImg2: require('../../assets/Web/job/exhibition/2.png'),
    }
  },
  methods:{
    messageLeave() {
      messageLeave({
        demandDescribe: this.queryParams.demandDescribe,
        name: this.queryParams.name,
        phone: this.queryParams.phone,
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.demandDescribe = ''
          this.queryParams.name = ''
          this.queryParams.phone = ''
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error('提交失败');
        }

      })
    },
  }
}
</script>
<style lang="scss" scoped>
.job {
  .jobTop {
    width: 100%;
    height: 453PX;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    img {
    height: 453PX;
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    .text{
      p:first-child{
        font-weight: bold;
        font-size: 45.56PX;
        color: #fff;
        letter-spacing: 4PX;
        margin-bottom: 21PX;
      }
      p:last-child{
        font-family: SourceHanSansCN-Medium;
        font-size: 32.75PX;
        color: #fff;
        letter-spacing: 4PX;
      }
    }
  }
  .jobProblem {
    margin-top: 36PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    img:first-child {
      width: 411PX;
      height: 43PX;
    }
    img:last-child {
      margin-top: 53PX;
      width: 966PX;
      height: 526PX;
    }
  }
  .jobProcess {
    margin-top: 43PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    img:first-child {
      width: 258.9PX;
      height: 43.5PX;
    }
    img:last-child {
      margin-top: 50PX;
      width: 970.7PX;
      height:292.4PX;
    }
  }
  .jobAdvantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #444581;
    height: 349.5PX;
    .jobAdvantage-top {
      margin-top: 44PX;
      img {
        width: 258.9PX;
        height: 43.5PX;
      }
    }
    .jobAdvantage-bottom {
      margin-top: 59PX;
      .jobAdvantage-bottom-list {
        display: flex;
        .jobAdvantage-bottom-list-item:last-child {
          margin-right: 0PX;
        }
        .jobAdvantage-bottom-list-item {
          margin-right: 69PX;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 48.5PX;
            height: 59.9PX;
          }
          p:nth-child(2) {
            font-family: SourceHanSansCN-Medium;
            font-size: 22PX;
            letter-spacing: 1PX;
            color: #fff;
            margin-top: 19PX;
            margin-bottom: 14PX;
          }
          p {
            font-size: 12PX;
            letter-spacing: 1PX;
            color: #fff;
            line-height: 15.69PX;
          }
        }
      }
    }
  }
  .jobExhibition {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #A6CDFF;
    height: 827.3PX;
    margin-top: 50PX;
    .jobExhibition-top {
      margin-top: 64PX;
      img {
        width: 227.5PX;
        height: 43.5PX;
      }
    }
 .jobExhibition-center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 43PX;
    margin-bottom: 20PX;
    div{
        width: 242.5PX;
        height: 49.9PX;
        font-size: 28.53PX;
        font-family: SourceHanSansCN-Medium;
        border-radius: 24.96PX;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1PX;
        color: #FFFFFF;
    }
    div:first-child{
        background-color:#B5B5B5 ;
    }
    div:last-child{
        background-color:#68AAFF ;
    }
    img{
        width: 85.6PX;
        height: 85.6PX;
        margin-left: 78PX;
        margin-right: 78PX;
    }
    }
    .jobExhibition-bottom{
        img{
           width: 370.9PX;
           height: 499.3PX; 
        }
        img:first-child{
            margin-right: 114PX;
        }
    }
  }
}
</style>